import React from 'react';
import { Col, Row, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { actorIsAdmin, actorIsRoot } from '../54origins/utils54origins';
import { getDefaultAdminGroupUUID } from './selectors';
import BaseTag from '../_ui/BaseTag/BaseTag';

function ActorListOfTags({ actor, className, isUserBanned }) {
  const adminGroupUUID = useSelector(getDefaultAdminGroupUUID);

  const {
    actor_type: actorType,
    uinfo: {
      isRobot = false,
      group_name = '',
      groups = [],
    } = {},
  } = actor || {};

  return (
    <Row className={className}>
      {actorIsRoot(actor) && (
        <Col>
          <BaseTag className="tag-purple mr-2">
            ROOT
          </BaseTag>
        </Col>
      )}
      {(actorIsAdmin(actor) || groups?.includes(adminGroupUUID)) && (
        <Col>
          <BaseTag className="tag-volcano mr-2">
            ADMIN
          </BaseTag>
        </Col>
      )}
      { isUserBanned && (
        <Col>
          <BaseTag className="tag-red mr-2">
            BAN
          </BaseTag>
        </Col>
      )}
      {isRobot && <Col><BaseTag className="mr-2" color="volcano">ROBOT</BaseTag></Col>}
    </Row>
  );
}

export default ActorListOfTags;
