import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Space, Switch,
} from 'antd';
import {
  mdiClipboardTextMultipleOutline,
  mdiHexagonMultipleOutline,
  mdiLinkVariant,
  mdiToggleSwitchOutline, mdiTrashCanOutline,
} from '@mdi/js';

import InfoBlock from '../../../components/InfoBlock/InfoBlock';
import ServiceListingGroupLink from '../service/ServiceListingGroupLink';
import AddActorsForNewActor from './AddActorsForNewActor';
import AntButtonWithMDI from '../../../components/AntButtonWithMDI';
import GetActorsAndShowListOfActors from '../GetActorsAndShowListOfActors';
import HelpIconWithHover from '../../../components/HelpIconWithHover';

import { capitalize, capitalizeAndTranslateMsg } from '../../../mainUtils';
import BaseSwitch from '../../../_ui/BaseSwitch/BaseSwitch';

function ServiceInfoFormItems(props) {
  const {
    actorData,
    actorForm,
    actorUUID,
    creating,
    editMode,
    onSuccessActorUpdate,
    setFieldsIsChanged,
    rules,
  } = props;

  const { setFieldsValue } = actorForm;

  const { t, i18n } = useTranslation();

  const { uinfo } = actorData || {};
  const {
    listing_group,
    service_name,
    reserve_domains,
    groups = [],
  } = uinfo || {};

  const defaultArrOfInputs = reserve_domains?.map?.((item, key) => ({
    id: key,
    value: item,
  })) || [];

  const [arrOfInputs, setNewArrOfInputs] = useState(defaultArrOfInputs);
  const [switchValue, changeSwitchValue] = useState(false);

  const isAuth = service_name === 'auth';
  const isEn = i18n?.language === 'en';

  const changeInput = (e) => {
    const newArr = [...arrOfInputs];
    arrOfInputs[e.target.name].value = e.target.value;

    setNewArrOfInputs(newArr);
  };

  const getSelectedActorsForNewService = ({ addActorsList }) => {
    setFieldsValue({
      addActorsList,
    });
  };

  const addReserveDomain = () => {
    setNewArrOfInputs([...arrOfInputs, {
      id: arrOfInputs.length,
      value: '',
    }]);
  };

  const removeReserveDomain = (id) => {
    setNewArrOfInputs([...arrOfInputs].filter((item) => item.id !== id));
  };

  useEffect(() => {
    setFieldsValue({
      reserve_domains: arrOfInputs.flatMap((item) => (item.value ? [item.value] : [])),
    });
    setFieldsIsChanged?.(true);
  }, [JSON.stringify(arrOfInputs)]);

  useEffect(() => {
    setNewArrOfInputs(defaultArrOfInputs);
  }, [reserve_domains]);

  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <InfoBlock
        iconPath={mdiClipboardTextMultipleOutline}
        title={capitalizeAndTranslateMsg('auth.headers.service_detail', 'service detail')}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.service_name', 'service name')}
              name="service_name"
              rules={rules.service_name}
            >
              <Input
                placeholder={capitalizeAndTranslateMsg(t('auth.placeholders.enter', { value: t('auth.headers.service_name') }))}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.domain', 'domain')}
              name="service_domain"
              rules={rules.service_domain}
            >
              <Input
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.domain', 'domain') }))}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={(
                <>
                  {capitalizeAndTranslateMsg('auth.headers.internal_domain', 'internal domain')}
                  <HelpIconWithHover
                    toolTipText={isEn ? 'An optional attribute that implies the address of the service within the local network of the biome (if possible)\n'
                        + 'The presence of Internal domain significantly speeds up services communications'
                      : 'Опциональный атрибут, который подразумевает собой адрес сервиса внутри локальной сети биома (если возможно)\n'
                        + 'Наличие Interal domain значительно ускоряет межсервисные коммуникации'}
                    iconClassName="ml-1"
                  />
                </>
              )}
              name="internal_service_domain"
              rules={rules.internal_service_domain}
            >
              <Input
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.internal_domain', 'internal domain') }))}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={(
                <>
                  {capitalizeAndTranslateMsg('auth.headers.d', 'reserve domains')}
                  <HelpIconWithHover
                    toolTipText={isEn ? 'An optional attribute that implies an additional list of service addresses that will be used in the queue during Auth service communication with the selected service if the main (or any previous) address is unavailable.'
                      : 'Опциональный атрибут, который подразумевает собой список дополнительных адресов сервиса, которые будут использоваться по очереди в коммуникации Auth сервиса с выбранным сервисом в том случае, когда основной(либо любой предыдущий) адрес недоступен.'}
                    iconClassName="ml-1"
                  />
                </>
              )}
            >
              <Space direction="vertical" className="w-full">
                {arrOfInputs.map((item) => (
                  <Row gutter={4}>
                    <Col span={22}>
                      <Input
                        disabled={!editMode}
                        name={item?.id}
                        value={item?.value}
                        onChange={changeInput}
                      />
                    </Col>
                    <Col span={2}>
                      <AntButtonWithMDI
                        disabled={!editMode}
                        onClick={() => removeReserveDomain(item?.id)}
                        pathForMdi={mdiTrashCanOutline}
                      />
                    </Col>
                  </Row>
                ))}
                <AntButtonWithMDI
                  disabled={!editMode}
                  onClick={addReserveDomain}
                  label="Add reserve domain"
                  className="button-primary-outlined"
                  size="small"
                />
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.description', 'description')}
              name="description"
              rules={rules.description}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.description') }))}
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          {creating && (
          <Col span={24}>

            <Form.Item
              label="UUID"
              name="uuid"
              rules={rules.uuid}
            >
              <Input
                placeholder={capitalize(t('auth.placeholders.enter', { value: 'UUID' }))}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          )}
          <Col span={24}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.biome_public_key', 'biome public key')}
              name="initial_key"
              rules={rules.initial_key}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.public_key') }))}
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                disabled={!editMode}
                maxLength={130}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      <Row>
        <Col span={12}>
          {!isAuth && !creating && (
            <InfoBlock
              iconPath={mdiLinkVariant}
              title={capitalizeAndTranslateMsg('auth.headers.listingGroup', 'listing group')}
            >
              <ServiceListingGroupLink
                listingGroupUUID={listing_group}
                actorUUID={actorUUID}
                onSuccessActorUpdate={onSuccessActorUpdate}
              />
            </InfoBlock>
          )}
        </Col>
      </Row>
      <InfoBlock
        iconPath={mdiHexagonMultipleOutline}
        title={capitalizeAndTranslateMsg('auth.headers.service_groups', 'service groups and subtype')}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="groups"
            >
              {creating ? (
                <AddActorsForNewActor
                  actorType="newService"
                  btnModalLabel="add group"
                  onSaveCallBack={getSelectedActorsForNewService}
                />
              ) : (
                <GetActorsAndShowListOfActors
                  actorData={actorData}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      {/* <InfoBlock */}
      {/*  iconPath={mdiNoteEditOutline} */}
      {/*  title={capitalizeAndTranslateMsg('auth.headers.comment', 'comment')} */}
      {/* > */}
      {/*  <Row> */}
      {/*    <Col span={24}> */}
      {/*      <Form.Item */}
      {/*        name="comment" */}
      {/*        rules={rules.comment} */}
      {/*      > */}
      {/*        <Input.TextArea */}
      {/*          placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.comment') }))} */}
      {/*          autoSize={{ */}
      {/*            minRows: 3, */}
      {/*            maxRows: 5, */}
      {/*          }} */}
      {/*          disabled={!editMode} */}
      {/*        /> */}
      {/*      </Form.Item> */}
      {/*    </Col> */}
      {/*  </Row> */}
      {/* </InfoBlock> */}
      {creating && (
        <InfoBlock
          title={capitalizeAndTranslateMsg('auth.labels.create_service_without_listing_group', 'Create service without listing group')}
          iconPath={mdiToggleSwitchOutline}
        >
          <Row>
            <Col>
              <Form.Item
                name="listingGroupFlag"
              >
                <BaseSwitch
                  checkedChildren={capitalizeAndTranslateMsg('auth.buttons.yes', 'yes')}
                  unCheckedChildren={capitalizeAndTranslateMsg('auth.buttons.no', 'no')}
                  checked={switchValue}
                  onClick={() => changeSwitchValue(!switchValue)}
                />
              </Form.Item>
            </Col>
          </Row>
        </InfoBlock>
      )}
    </div>
  );
}

export default ServiceInfoFormItems;

ServiceInfoFormItems.propTypes = {
  actorData: PropTypes.any,
  actorForm: PropTypes.object.isRequired,
  actorUUID: PropTypes.string,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  onSuccessActorUpdate: PropTypes.func,
  rules: PropTypes.object,
};
