import PropTypes from 'prop-types';
import React, { memo } from 'react';
import {
  mdiAccountDetailsOutline,
  mdiHelpCircle,
  mdiMonitorScreenshot,
  mdiTextBoxMultipleOutline,
} from '@mdi/js';
import {
  Col, Form, Input, InputNumber, Row, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';

import InfoBlock from '../../../components/InfoBlock/InfoBlock';
import AddActorsForNewActor from './AddActorsForNewActor';
import ListOfPIForActor from '../../../publicInterfaces/components/ListOfPIForActor';
import GetActorsAndShowListOfActors from '../GetActorsAndShowListOfActors';

import { capitalize, capitalizeAndTranslateMsg } from '../../../mainUtils';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';

const WeightLabel = memo(() => (
  <Tooltip
    title={capitalizeAndTranslateMsg('auth.tooltips.help_weight')}
  >
    <div className="flex items-center">
      {capitalizeAndTranslateMsg('auth.headers.weight', 'weight')}
      <BaseIcon path={mdiHelpCircle} size={1} className="ml-1" />
    </div>
  </Tooltip>
));

function GroupInfoFormItems(props) {
  const {
    actorData,
    actorForm,
    editMode,
    rules,
    creating,
  } = props;

  const {
    uinfo: {
      public_interfaces: publicInterfaces,
    } = {},
  } = actorData || {};

  const getSelectedActorsForNewGroup = ({ addActorsList }) => {
    actorForm.setFieldsValue({
      addActorsList,
    });
  };

  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <InfoBlock
        iconPath={mdiTextBoxMultipleOutline}
        title={capitalizeAndTranslateMsg('auth.headers.group_detail', 'group detail')}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.group_name', 'group name')}
              name="group_name"
              rules={rules.group_name}
            >
              <Input
                placeholder={
                  capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.group_name') })
                  }
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<WeightLabel />}
              name="weight"
              rules={rules.weight}
            >
              <InputNumber
                className="w-full"
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.group_weight') }))}
                min={1}
                max={4294967297}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={capitalize(t('auth.headers.description', 'description'))}
              name="description"
              rules={rules.description}
            >
              <Input.TextArea
                placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.description') }))}
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      <InfoBlock
        iconPath={mdiAccountDetailsOutline}
        title={capitalizeAndTranslateMsg('auth.headers.user_groups', 'users and subtype')}
      >
        <Row>
          <Col span={12}>
            {creating ? (
              <AddActorsForNewActor
                actorType="newGroup"
                btnModalLabel="add users"
                onSaveCallBack={getSelectedActorsForNewGroup}
              />
            ) : (
              <GetActorsAndShowListOfActors
                actorData={actorData}
              />
            )}
          </Col>
        </Row>
      </InfoBlock>

      {publicInterfaces && (
        <InfoBlock
          iconPath={mdiMonitorScreenshot}
          title={capitalize(t('54origins.noun,public', 'public interfaces'))}
        >
          <ListOfPIForActor
            className="mb-2"
            publicInterfaces={publicInterfaces}
          />
        </InfoBlock>
      )}
      {/* <InfoBlock */}
      {/*  iconPath={mdiNoteEditOutline} */}
      {/*  title={capitalize(t('auth.headers.comment', 'comment'))} */}
      {/* > */}
      {/*  <Row> */}
      {/*    <Col span={24}> */}
      {/*      <Form.Item */}
      {/*        name="comment" */}
      {/*        rules={rules.comment} */}
      {/*      > */}
      {/*        <Input.TextArea */}
      {/*          placeholder={capitalize(t('auth.placeholders.enter', { value: t('auth.headers.comment') }))} */}
      {/*          autoSize={{ */}
      {/*            minRows: 3, */}
      {/*            maxRows: 5, */}
      {/*          }} */}
      {/*          disabled={!editMode} */}
      {/*        /> */}
      {/*      </Form.Item> */}
      {/*    </Col> */}
      {/*  </Row> */}
      {/* </InfoBlock> */}
    </div>
  );
}

export default GroupInfoFormItems;

GroupInfoFormItems.propTypes = {
  actorData: PropTypes.any,
  actorForm: PropTypes.object.isRequired,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  rules: PropTypes.object,
};
