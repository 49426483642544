import PropTypes from 'prop-types';
import React, {
  memo, useEffect, useState,
} from 'react';
import { capitalize, toLower } from 'lodash';
import {
  Button, Dropdown,
} from 'antd';
import Icon from '@mdi/react';
import { mdiDotsVertical, mdiPencil, mdiTrashCanOutline } from '@mdi/js';
import { useSelector } from 'react-redux';

import GenerateKeyPairForActorBtn from './GenerateKeyPairForActorBtn';
import SyncDashboardModal from '../syncDashboard/SyncDashboardModal';
import MasqueradingBtn54origins from '../../../54origins/components/MasqueradingBtn54origins';
import AntButtonWithMDI from '../../../components/AntButtonWithMDI';
import ButtonWithPopConfirmAntDesign54origins from '../../../54origins/components/ButtonWithPopConfirmAntDesign54origins';
import AddPublicInterfaceToGroup from '../../../publicInterfaces/components/AddPublicInterfaceToGroup';
import CheckKeyPairOfServiceBtn from './CheckKeyPairOfServiceBtn';
import ExistingUsersOnServiceBtnModal from '../syncDashboard/ExistingUsersOnServiceBtnModal';
import AntButtonWithModal from '../../../components/AntButtonWithModal';
import PermissionDigest from '../../../permissions/components/PermissionDigest';

import { stopPropagation } from '../../../54origins/utils54origins';
import useAuthActorsRequests from '../../hooks/useAuthActorsRequests';
import { antNotification, capitalizeAndTranslateMsg } from '../../../mainUtils';
import { isInfoFetching } from '../../selectors';
import { getMeIsRootOrAdmin } from '../../../profile/selectors';
import useURLParams from '../../../hooks/useURLParams';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../../_ui/BaseButton/BaseButton';
import DeleteButton from '../../../components/DeleteButton';

function AddActorToStaffOrTestStaff({
  onSaveCallBack,
  actor,
  groupName,
  groupUUID,
}) {
  const { requestUpdateActorOnAuth } = useAuthActorsRequests();

  const {
    actor_type: actorType,
    uuid,
  } = actor || {};

  const btnOnClick = () => {
    requestUpdateActorOnAuth({
      actor_type: actorType,
      add_actors_list: [groupUUID],
      uuid,
    }).then(() => {
      antNotification.defaultSuccess();

      onSaveCallBack?.();
    });
  };

  return groupUUID && (
  <AntButtonWithMDI
    label={`Add to ${capitalize(groupName)}54`}
    className="button-primary-outlined w-full"
    onClick={btnOnClick}
  />
  );
}

function ActorSubTypeOrRobotBtn({
  actorData,
  mode,
  onSuccessCallback,
}) {
  const { requestUpdateActorOnAuth } = useAuthActorsRequests();
  const {
    actor_type,
    uinfo: {
      internal_user = false,
      isRobot = false,
    } = {},
    uuid,
  } = actorData || {};

  const onClick = () => {
    requestUpdateActorOnAuth({
      actor_type,
      uinfo: {
        ...actorData?.uinfo,
        ...(mode === 'internalUser' ? { internal_user: !internal_user } : {}),
        ...(mode === 'isRobot' ? { isRobot: !isRobot } : {}),
      },
      uuid,
    }).then(() => {
      antNotification.defaultSuccess();

      onSuccessCallback?.();
    });
  };

  const title = mode === 'isRobot' ? `Make actor is ${isRobot ? 'NOT A ROBOT' : 'ROBOT'}?` : `change actor to ${internal_user ? 'EXTERNAL' : 'INTERNAL'}?`;
  const label = mode === 'isRobot' ? isRobot ? 'Robot' : 'Not a robot' : internal_user ? 'Internal' : 'External';

  return (
    <ButtonWithPopConfirmAntDesign54origins
      popConfirmPlacement="left"
      popConfirmTitle={title}
      label={label}
      pathForMdi={mdiPencil}
      className="w-full button-primary-outlined"
      onClick={onClick}
    />
  );
}

function UtilityBtnForActorInfo({
  actorData,
  actorType,
  onSavePublicInterfaceCallback,
  onSuccessUpdate,
  onSuccessDelete,
  actorURLUUID,
  isDefault,
}) {
  const isFetching = useSelector(isInfoFetching);
  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);

  const { requestGetListOfActorsOnAuth } = useAuthActorsRequests();

  const { setSearchParams } = useURLParams();

  const [groups54, setGroups54] = useState(null);

  const {
    uuid: actorUUID,
    uinfo: {
      public_interfaces: publicInterfaces,
    } = {},
  } = actorData || {};

  const getGroups54 = () => {
    requestGetListOfActorsOnAuth({
      actor_type: 'group',
      uinfo: {
        group_name: ['teststaff54', 'staff54', 'Teststaff54', 'Staff54'],
      },
    }, []).then((res) => {
      setGroups54(res?.actors?.map((item) => ({
        name: item?.uinfo?.group_name,
        uuid: item?.uuid,
      })));
    });
  };

  const isUser = actorType === 'user' || actorType === 'classic_user';
  const isService = actorType === 'service';
  const isGroup = actorType === 'group';

  const changeActorModal = (mode) => {
    setSearchParams({
      modal: mode,
    });
  };

  useEffect(() => {
    getGroups54();
  }, []);

  return (
    <>
      <Dropdown
        menu={{
          items: [
            (isUser && groups54?.find((item) => toLower(item?.name) === 'staff54') && {
              label: (
                <AddActorToStaffOrTestStaff
                  groupName="staff"
                  actor={actorData}
                  groupUUID={groups54?.find((item) => toLower(item?.name) === 'staff54')?.uuid}
                />
              ),
              key: 10,
            }),
            (isUser && groups54?.find((item) => toLower(item?.name) === 'teststaff54') && {
              label: (
                <AddActorToStaffOrTestStaff
                  groupName="test staff"
                  actor={actorData}
                  groupUUID={groups54?.find((item) => toLower(item?.name) === 'teststaff54')?.uuid}
                />
              ),
              key: 20,
            }),
            (actorType === 'user' && {
              label: (
                <GenerateKeyPairForActorBtn actor={actorData} />
              ),
              key: 30,
            }),
            {
              label: (
                <AntButtonWithMDI
                  className="button-primary-outlined w-full"
                  onClick={() => changeActorModal('syncDashboard')}
                  disabled={isFetching}
                  // label={translateFromLocalDict('syncDashboard')}
                  label="Sync dashboard"
                />
              ),
              key: 40,
            },
            {
              label: (
                <AntButtonWithModal
                  btnClassName="button-primary-outlined w-full"
                  btnLabel="Permissions digest"
                >
                  <PermissionDigest isModal />
                </AntButtonWithModal>
              ),
              key: 41,
            },
            (isService && meIsRootOrAdmin && {
              label: (
                <CheckKeyPairOfServiceBtn serviceUUID={actorUUID} />
              ),
              key: 42,
            }),
            (isGroup && {
              label: (
                <AddPublicInterfaceToGroup
                  onSaveCallback={onSavePublicInterfaceCallback}
                  actorUUID={actorUUID}
                  defaultRowSelection={publicInterfaces?.map((item) => item.id.toString())}
                />
              ),
              key: 50,
            }),
            {
              label: (
                <ActorSubTypeOrRobotBtn
                  actorData={actorData}
                  mode="internalUser"
                  onSuccessCallback={onSuccessUpdate}
                />
              ),
              key: 60,
            },
            {
              label: (
                <ActorSubTypeOrRobotBtn
                  actorData={actorData}
                  mode="isRobot"
                  onSuccessCallback={onSuccessUpdate}
                />

              ),
              key: 70,
            },
            (isUser && {
              label: (
                <MasqueradingBtn54origins
                  actorUUID={actorUUID}
                  btnLabel="Masquerading"
                  btnSize="default"
                  tooltipVisible={false}
                  className="w-full"
                />
              ),
              key: 80,
            }
            ),
            {
              label: (
                <DeleteButton
                  onSuccess={onSuccessDelete}
                  uuid={actorURLUUID}
                  disabled={isDefault || isFetching}
                  id={55}
                  maxHeight={false}
                >
                  <AntButtonWithMDI
                    className="button-danger-outlined w-full"
                    disabled={isDefault || isFetching}
                    pathForMdi={mdiTrashCanOutline}
                    label={capitalizeAndTranslateMsg('auth.buttons.remove_actor', 'remove actor')}
                  />
                </DeleteButton>
              ),
              key: 90,
            },
          ],
        }}
        onClick={stopPropagation}
      >
        <BaseButton
          className="button-primary-outlined"
          size="small"
        >
          Utility
          <BaseIcon path={mdiDotsVertical} size={1} />
        </BaseButton>
      </Dropdown>
      <SyncDashboardModal />
      <ExistingUsersOnServiceBtnModal serviceUUID={actorUUID} />
    </>

  );
}

export default memo(UtilityBtnForActorInfo);

AddActorToStaffOrTestStaff.propTypes = {
  actor: PropTypes.any,
  groupName: PropTypes.string,
  groupUUID: PropTypes.string,
  onSaveCallBack: PropTypes.func,
};

UtilityBtnForActorInfo.propTypes = {
  actorData: PropTypes.any,
  actorType: PropTypes.string,
  onSavePublicInterfaceCallback: PropTypes.func,
  onSuccessUpdate: PropTypes.func,
  onSuccessDelete: PropTypes.func,
  actorURLUUID: PropTypes.string,
  isDefault: PropTypes.bool,
};

ActorSubTypeOrRobotBtn.propTypes = {
  actorData: PropTypes.any,
  mode: PropTypes.string,
  onSuccessCallback: PropTypes.func,
};
