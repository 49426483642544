import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import axiosRequest from '../api/api';
import { ActorsConstants } from '../actors/constants/actionTypes';
import { capitalize } from '../mainUtils';
import useURLParams from '../hooks/useURLParams';

function DeleteButton({
  children,
  data,
  disabled,
  onFailure,
  onSuccess,
  targetType = 'actor',
  uuid,
  id,
  maxHeight = true,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // console.log('RENDER DELETE', id);

  const getUrl = () => {
    switch (targetType) {
      case 'actor':
        return 'delete/actor';
      case 'public_interface':
        return 'public_interfaces/delete';
      case 'certificate':
        return 'services/certificates/delete';
      default:
        return '';
    }
  };

  const getTypes = () => {
    switch (targetType) {
      case 'actor':
        return [
          ActorsConstants.REMOVE_ACTOR_REQUEST,
          ActorsConstants.REMOVE_ACTOR_SUCCESS,
          ActorsConstants.REMOVE_ACTOR_FAILURE,
          // ActorsConstants.REMOVE_ACTOR_CANCEL
        ];
      case 'public_interface':
      case 'certificate':
        return [];
      default:
        return [];
    }
  };

  const requestDeleteActor = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        getUrl(),
        getTypes(),
        data || { uuid },
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const showDeleteConfirm = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    Modal.confirm({
      title: capitalize(t('auth.buttons.remove_actor', 'remove actor')),
      content: capitalize(t(
        `auth.messages.delete_sure_${targetType}`,
        `are you sure you want to delete this ${targetType}?`,
      )),
      maskClosable: true,
      centered: true,
      closable: true,
      okText: capitalize(t('auth.buttons.remove', 'remove')),
      okType: 'danger',
      okButtonProps: {
        className: 'button-danger',
      },
      cancelText: capitalize(t('auth.buttons.cancel', 'cancel')),
      cancelButtonProps: {
        className: 'button-secondary-outlined',
      },
      onOk() {
        requestDeleteActor()
          .then(() => onSuccess && onSuccess())
          .catch(() => onFailure && onFailure());
      },
    });
  };

  return (
    <div
      onClick={showDeleteConfirm}
      style={{ maxHeight: maxHeight ? 24 : 'auto' }}
    >
      {children}
    </div>
  );
}

export default memo(DeleteButton);

DeleteButton.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  targetType: PropTypes.string,
  uuid: PropTypes.string,
  maxHeight: PropTypes.bool,
};
