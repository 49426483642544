import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles/tailwind.css';
import 'dayjs/locale/ru';
// import './fonts/noir-pro/styles.css';
import './styles/remained_styles.scss';
import './styles/antDesignOverrides/overrides.scss';
import './i18n';
import { ConfigProvider } from 'antd';
import App from './app/App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <App />,
  );
}
